import { TextField, TextFieldProps } from '@material-ui/core'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const FormTextField: FC<TextFieldProps> = (props) => {
    const { name, onChange, ...rest } = props
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange: onChangeRHF, onBlur, value },
                fieldState: { error },
            }) => (
                <TextField
                    name={name}
                    error={!!error}
                    helperText={error?.message}
                    onBlur={onBlur}
                    onChange={(e) => {
                        onChange?.(e)
                        onChangeRHF(e)
                    }}
                    value={value}
                    {...rest}
                />
            )}
        />
    )
}

FormTextField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
}

export default FormTextField
