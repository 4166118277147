import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { createContext, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { accountApi } from '../api/accountApi'
import { authApi } from '../api/authApi'
import eventBus from '../lib/eventBus'
import tokenService from '../lib/tokenService'
import type { Account } from '../types/account'
import type Team from '../types/Team'
import jwtDecode from '../utils/jwtDecode'
import tokenStorageService from '../utils/tokenStorageService'

interface State {
    isInitialized: boolean
    isAuthenticated: boolean
    user: Account | null
    permissions: string[] | null
    team: Team | null
    impersonator: Account | null
    assignedTeams: Team[] | null
    assignedUsers: Account[] | null
}

interface AuthContextValue extends State {
    login: (email: string, password: string) => Promise<void>
    logout: () => Promise<void>
    selectUser: (userId: string) => Promise<void>
    selectTeam: (teamId: string) => Promise<void>
    deselectUser: () => Promise<void>
}

interface AuthProviderProps {
    children: ReactNode
}

type InitializeAction = {
    type: 'INITIALIZE'
    payload: {
        isAuthenticated: boolean
        user: Account | null
        permissions: string[] | null
        team: Team | null
        impersonator: Account | null
        assignedTeams: Team[] | null
        assignedUsers: Account[] | null
    }
}

type LoginAction = {
    type: 'LOGIN'
    payload: {
        user: Account
        assignedTeams: Team[] | null
        assignedUsers: Account[] | null
    }
}

type LogoutAction = {
    type: 'LOGOUT'
}

type Action = InitializeAction | LoginAction | LogoutAction

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    permissions: null,
    team: null,
    impersonator: null,
    assignedTeams: null,
    assignedUsers: null,
}

const handlers: Record<string, (state: State, action: Action) => State> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const {
            isAuthenticated,
            user,
            permissions,
            team,
            impersonator,
            assignedTeams,
            assignedUsers,
        } = action.payload

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            permissions,
            team,
            impersonator,
            assignedTeams,
            assignedUsers,
        }
    },
    LOGIN: (state: State, action: LoginAction): State => {
        const { user, assignedTeams, assignedUsers } = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user,
            assignedTeams,
            assignedUsers,
        }
    },
    LOGOUT: (state: State): State => ({
        ...state,
        isAuthenticated: false,
        user: null,
        permissions: null,
        team: null,
        impersonator: null,
        assignedTeams: null,
        assignedUsers: null,
    }),
}

const reducer = (state: State, action: Action): State =>
    handlers[action.type] ? handlers[action.type](state, action) : state

const AuthContext = createContext<AuthContextValue>({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    selectUser: () => Promise.resolve(),
    selectTeam: () => Promise.resolve(),
    deselectUser: () => Promise.resolve(),
})

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props
    const [state, dispatch] = useReducer(reducer, initialState)
    const navigate = useNavigate()

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            await init()
        }

        initialize()
    }, [])

    useEffect(() => {
        eventBus.on('logout', async () => {
            await logout()
        })
        eventBus.on('token-refreshed', async () => {
            await init()
        })
    }, [])

    const init = async (): Promise<void> => {
        try {
            const accessToken = await tokenService.getToken()

            if (accessToken) {
                const { team, impersonator, ...claims } = jwtDecode(accessToken)
                const permissions =
                    claims['https://gjb-frankfurt.de/claims/permission'] || null
                let userInfo: Account = null
                let teamInfo: Team = null
                let impersonatorInfo: Account = null

                if (team && impersonator) {
                    ;[userInfo, teamInfo, impersonatorInfo] = await Promise.all(
                        [
                            accountApi.me(),
                            authApi.teamInfo(),
                            authApi.impersonatorInfo(),
                        ]
                    )
                } else if (team && !impersonator) {
                    ;[userInfo, teamInfo] = await Promise.all([
                        accountApi.me(),
                        authApi.teamInfo(),
                    ])
                } else if (!team && impersonator) {
                    ;[userInfo, impersonatorInfo] = await Promise.all([
                        accountApi.me(),
                        authApi.impersonatorInfo(),
                    ])
                } else {
                    userInfo = await accountApi.me()
                }

                const [assignedTeams, assignedUsers] = await Promise.all([
                    accountApi.assignedTeams(),
                    accountApi.assignedUsers(),
                ])

                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: true,
                        user: userInfo,
                        permissions,
                        team: teamInfo,
                        impersonator: impersonatorInfo,
                        assignedTeams,
                        assignedUsers,
                    },
                })
            } else {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        permissions: null,
                        team: null,
                        impersonator: null,
                        assignedTeams: null,
                        assignedUsers: null,
                    },
                })
            }
        } catch (err) {
            console.error(err)
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated: false,
                    user: null,
                    permissions: null,
                    team: null,
                    impersonator: null,
                    assignedTeams: null,
                    assignedUsers: null,
                },
            })
        }
    }

    const login = async (email: string, password: string): Promise<void> => {
        const accessToken = await authApi.login({ email, password })
        tokenStorageService.setAccessToken(accessToken)

        await init()
    }

    const logout = async (): Promise<void> => {
        try {
            await authApi.logout()
        } finally {
            tokenStorageService.clearToken()
            dispatch({ type: 'LOGOUT' })
        }
    }

    const selectUser = async (userId: string): Promise<void> => {
        const accessToken = await authApi.selectUser(userId)
        tokenStorageService.setAccessToken(accessToken)
        navigate('/dashboard')

        await init()
    }

    const selectTeam = async (teamId: string): Promise<void> => {
        const accessToken = await authApi.selectTeam(teamId)
        tokenStorageService.setAccessToken(accessToken)
        navigate('/dashboard')

        await init()
    }

    const deselectUser = async (): Promise<void> => {
        const accessToken = await authApi.deselectUser()
        tokenStorageService.setAccessToken(accessToken)
        navigate('/dashboard')

        await init()
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                selectUser,
                selectTeam,
                deselectUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AuthContext
