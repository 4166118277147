import StyledEngineProvider from '@material-ui/core/StyledEngineProvider'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import '@progress/kendo-theme-material/dist/all.css'
import deLocale from 'date-fns/locale/de'
import 'nprogress/nprogress.css'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { HelmetProvider } from 'react-helmet-async'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-quill/dist/quill.snow.css'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { AuthProvider } from './contexts/JWTContext'
import { SettingsProvider } from './contexts/SettingsContext'
import store from './store'

const envCSS = {
    borderWidth: '0px 20px',
    borderStyle: 'solid',
    borderColor: 'red',
}

ReactDOM.render(
    <div style={process.env.REACT_APP_ENVIROMENT === 'test' ? envCSS : {}}>
        {/* {console.log('REACT_APP_ENVIROMENT', process.env.REACT_APP_ENVIROMENT)} */}
        <StrictMode>
            <HelmetProvider>
                <ReduxProvider store={store}>
                    <StyledEngineProvider injectFirst>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={deLocale}
                        >
                            <SettingsProvider>
                                <BrowserRouter>
                                    <AuthProvider>
                                        <App />
                                    </AuthProvider>
                                </BrowserRouter>
                            </SettingsProvider>
                        </LocalizationProvider>
                    </StyledEngineProvider>
                </ReduxProvider>
            </HelmetProvider>
        </StrictMode>
    </div>,
    document.getElementById('root')
)
