import PersonSearchIcon from '@material-ui/icons/PersonSearch'
import {
    Avatar,
    Box,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    Tooltip,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import useAuth from '../../hooks/useAuth'
import UsersIcon from '../../icons/Users'

interface RepresentationsPopoverProps {
    onSelectUser?: (user: any) => void
}

const RepresentationsPopover: FC<RepresentationsPopoverProps> = (props) => {
    const { onSelectUser } = props
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const [open, setOpen] = useState<boolean>(false)
    const auth = useAuth()

    const handleOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Vertretungen">
                <IconButton
                    color="inherit"
                    ref={anchorRef}
                    onClick={handleOpen}
                >
                    <UsersIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {
                        p: 2,
                        width: 320,
                    },
                }}
            >
                <Typography variant="h6">Vertretungen</Typography>
                <Box sx={{ mt: 2 }}>
                    <List disablePadding>
                        {auth.assignedUsers.map((user) => (
                            <ListItem
                                disableGutters
                                key={user.id}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        onClick={() => onSelectUser?.(user)}
                                    >
                                        <PersonSearchIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar src={null} />
                                </ListItemAvatar>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Link
                                            color="textPrimary"
                                            noWrap
                                            underline="none"
                                            variant="subtitle2"
                                        >
                                            {user.firstName} {user.lastName}
                                        </Link>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>
        </>
    )
}

RepresentationsPopover.propTypes = {
    onSelectUser: PropTypes.func,
}

export default RepresentationsPopover
