import { experimentalStyled } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

interface MainLayoutProps {
    children?: ReactNode
}

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100%',
}))

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
    return <MainLayoutRoot>{children || <Outlet />}</MainLayoutRoot>
}

MainLayout.propTypes = {
    children: PropTypes.node,
}

export default MainLayout
