import {
    Box,
    Button,
    FormHelperText,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import { Formik } from 'formik'
import type { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import { authApi } from '../../api/authApi'
import useMounted from '../../hooks/useMounted'

const PasswordResetEditForm: FC = (props) => {
    const mounted = useMounted()
    const [searchParams] = useSearchParams()

    return (
        <Formik
            initialValues={{
                password: '',
                passwordConfirm: '',
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .matches(
                        /[a-z]+/,
                        'Mindestens ein Kleinbuchstabe ist erforderlich'
                    )
                    .matches(
                        /[A-Z]+/,
                        'Mindestens ein Großbuchstabe ist erforderlich'
                    )
                    .min(
                        8,
                        'Das Passwort ist zu kurz - es muss mindetens 8 Zeichen haben'
                    )
                    .matches(
                        /^.*((?=.*[?!@#$%^&*()\-_=+{};:,<.>]){1}).*$/,
                        'Mindestens ein Sonderzeichen ist erforderlich'
                    )
                    .required('Password ist erforderlich'),
                passwordConfirm: Yup.string().oneOf(
                    [Yup.ref('password'), null],
                    'Passwörter müssen übereinstimmen'
                ),
            })}
            onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
            ): Promise<void> => {
                try {
                    await authApi.resetPassword({
                        token: searchParams.get('token'),
                        userId: searchParams.get('userId'),
                        password: values.password,
                    })

                    if (mounted.current) {
                        setStatus({ success: true })
                        setErrors({
                            submit: 'Passwort erfolgreich zurückgesetzt',
                        })
                        setSubmitting(false)
                    }
                } catch (err) {
                    console.error(err)
                    if (mounted.current) {
                        setStatus({ success: false })
                        setErrors({ submit: err.response.data })
                        setSubmitting(false)
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }): JSX.Element => (
                <form noValidate onSubmit={handleSubmit} {...props}>
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Passwort"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(
                            touched.passwordConfirm && errors.passwordConfirm
                        )}
                        fullWidth
                        helperText={
                            touched.passwordConfirm && errors.passwordConfirm
                        }
                        label="Passwort bestätigen"
                        margin="normal"
                        name="passwordConfirm"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.passwordConfirm}
                        variant="outlined"
                    />

                    <Grid item md={12} sm={12} xs={12}>
                        <Typography
                            color="textSecondary"
                            sx={{ fontStyle: 'italic' }}
                        >
                            Das Passwort muss mindestens 8 Zeichen lang sein,
                            einen Groß- und Kleinbuchstaben enthalten sowie ein
                            Sonderzeichen.
                        </Typography>
                    </Grid>
                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            Passwort zurücksetzen
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    )
}

export default PasswordResetEditForm
