import { TextField, TextFieldProps } from '@material-ui/core'
import { DesktopDatePicker } from '@material-ui/lab'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface FormDatePickerProps {
    minDate?: Date
    maxDate?: Date
    shouldDisableDate?: (value: Date) => boolean
    onDateChange?: (value: Date | string) => void
}

const FormDatePicker: FC<FormDatePickerProps & TextFieldProps> = (props) => {
    const {
        name,
        disabled,
        minDate,
        maxDate,
        shouldDisableDate,
        onDateChange,
        ...rest
    } = props
    const { control, setValue } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
                <DesktopDatePicker
                    disabled={disabled}
                    mask="__.__.____"
                    value={value}
                    onChange={(newValue: Date) => {
                        if (!newValue) {
                            setValue(name, undefined, {
                                shouldValidate: true,
                                shouldDirty: true,
                            })
                            onDateChange?.(undefined)
                        } else if (Number.isNaN(newValue.getTime())) {
                            setValue(name, newValue, {
                                shouldValidate: true,
                                shouldDirty: true,
                            })
                            onDateChange?.(newValue)
                        } else {
                            setValue(name, format(newValue, 'yyyy-MM-dd'), {
                                shouldValidate: true,
                                shouldDirty: true,
                            })
                            onDateChange?.(format(newValue, 'yyyy-MM-dd'))
                        }
                    }}
                    defaultCalendarMonth={minDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    shouldDisableDate={shouldDisableDate}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            {...rest}
                        />
                    )}
                />
            )}
        />
    )
}

FormDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    shouldDisableDate: PropTypes.func,
    onDateChange: PropTypes.func,
}

export default FormDatePicker
