import type { AppBarProps } from '@material-ui/core'
import { AppBar, Badge, Box, IconButton, Toolbar } from '@material-ui/core'
import { experimentalStyled } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useConfirmation from '../../hooks/useConfirmationDialog'
import MenuIcon from '../../icons/Menu'
import UsersIcon from '../../icons/Users'
import type { User } from '../../types/user'
import Logo from '../Logo'
import AccountPopover from './AccountPopover'
import RepresentationsPopover from './RepresentationsPopover'

interface DashboardNavbarProps extends AppBarProps {
    onSidebarMobileOpen?: () => void
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
    ...(theme.palette.mode === 'light' && {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        color: theme.palette.primary.contrastText,
    }),
    ...(theme.palette.mode === 'dark' && {
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxShadow: 'none',
    }),
    zIndex: theme.zIndex.drawer + 100,
}))

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
    const { onSidebarMobileOpen, ...other } = props
    const auth = useAuth()
    const confirm = useConfirmation()

    const handleSelectUser = async (selectedUser: User): Promise<void> => {
        await auth.selectUser(selectedUser.id)
    }

    const handleDeselectUser = async (): Promise<void> => {
        confirm({
            variant: 'danger',
            catchOnCancel: false,
            title: 'Wollen Sie die Vertretung beenden?',
            description: 'Sie kehren zu ihrem Konto zurück.',
        }).then(async () => {
            await auth.deselectUser()
        })
    }

    return (
        <DashboardNavbarRoot {...other}>
            <Toolbar sx={{ minHeight: 64 }}>
                <IconButton
                    color="inherit"
                    onClick={onSidebarMobileOpen}
                    sx={{
                        display: {
                            lg: 'none',
                        },
                    }}
                >
                    <MenuIcon fontSize="small" />
                </IconButton>

                <RouterLink to="/">
                    <Logo
                        sx={{
                            display: {
                                lg: 'inline',
                                xs: 'none',
                            },
                            height: 40,
                            width: 40,
                        }}
                    />
                </RouterLink>

                <Box
                    sx={{
                        flexGrow: 1,
                        ml: 2,
                    }}
                />
                {auth.impersonator === null && (
                    <Box sx={{ ml: 1 }}>
                        <RepresentationsPopover
                            onSelectUser={handleSelectUser}
                        />
                    </Box>
                )}
                {auth.impersonator && (
                    <IconButton color="inherit" onClick={handleDeselectUser}>
                        <Badge color="secondary" variant="dot">
                            <UsersIcon fontSize="small" />
                        </Badge>
                    </IconButton>
                )}
                <Box sx={{ ml: 2 }}>
                    <AccountPopover />
                </Box>
            </Toolbar>
        </DashboardNavbarRoot>
    )
}

DashboardNavbar.propTypes = {
    onSidebarMobileOpen: PropTypes.func,
}

export default DashboardNavbar
