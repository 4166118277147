import { Autocomplete, TextField, TextFieldProps } from '@material-ui/core'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface FormAutoCompleteProps {
    options: any[]
    valueField: string
    displayField: string
    getOptionLabel?: (option: any) => string
    renderOption?: (props: any, option: any, state: any) => React.ReactNode
    isOptionEqualToValue?: (option: any, value: any) => boolean
    getOptionDisabled?: (option: any) => boolean
}

const FormAutoComplete: FC<FormAutoCompleteProps & TextFieldProps> = (
    props
) => {
    const {
        options,
        valueField,
        displayField,
        disabled,
        name,
        getOptionLabel,
        renderOption,
        onChange,
        isOptionEqualToValue,
        getOptionDisabled,
        ...rest
    } = props
    const { control, getValues, setValue } = useFormContext()

    const getValue = () => {
        if (getValues()[name] === '' || getValues()[name] === null) return null
        return options.find((x) => x[valueField] === getValues()[name])
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ fieldState: { error } }) => (
                <Autocomplete
                    disabled={disabled}
                    value={getValue()}
                    options={options}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionDisabled={getOptionDisabled}
                    getOptionLabel={(option): string =>
                        getOptionLabel?.(option) ?? option[displayField]
                    }
                    onChange={(_, val: any) => {
                        const newValue = val !== null ? val[valueField] : null
                        setValue(name, newValue, {
                            shouldValidate: true,
                            shouldTouch: true,
                        })
                        onChange?.(newValue)
                    }}
                    renderOption={
                        renderOption ??
                        ((params, option) => (
                            <li {...params} key={option[valueField]}>
                                {option[displayField]}
                            </li>
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...rest}
                            error={!!error}
                            helperText={error?.message}
                            name={name}
                        />
                    )}
                />
            )}
        />
    )
}

FormAutoComplete.propTypes = {
    options: PropTypes.array.isRequired,
    valueField: PropTypes.string.isRequired,
    displayField: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    getOptionLabel: PropTypes.func,
    renderOption: PropTypes.func,
    isOptionEqualToValue: PropTypes.func,
    getOptionDisabled: PropTypes.func,
    onChange: PropTypes.func,
}

export default FormAutoComplete
