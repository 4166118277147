import { MenuItem, Popover } from '@material-ui/core'
import PropTypes from 'prop-types'
import type { FC } from 'react'

interface DashboardSidebarPopoverProps {
    anchorEl: null | Element
    displayField: string
    valueField: string
    items: any[]
    onClose?: () => void
    onSelect?: (item: any) => void
    open?: boolean
}

const DashboardSidebarPopover: FC<DashboardSidebarPopoverProps> = (props) => {
    const {
        anchorEl,
        displayField,
        valueField,
        items,
        onSelect,
        onClose,
        open,
        ...other
    } = props

    const handleChange = (item: any): void => {
        onSelect?.(item)
        onClose?.()
    }

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            keepMounted
            onClose={onClose}
            open={!!open}
            PaperProps={{ sx: { width: 248 } }}
            transitionDuration={0}
            {...other}
        >
            {items.map((item) => (
                <MenuItem
                    key={item[valueField]}
                    onClick={() => handleChange(item)}
                >
                    {item[displayField]}
                </MenuItem>
            ))}
        </Popover>
    )
}

DashboardSidebarPopover.propTypes = {
    anchorEl: PropTypes.any,
    displayField: PropTypes.string.isRequired,
    valueField: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
}

export default DashboardSidebarPopover
