import axios from 'axios'
import tokenStorageService from '../../utils/tokenStorageService'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
})

/* Appending old token to extract some data about previous session
 * USUALLY THIS TYPE OF INTERCEPTOR IS NOT NEEDED
 */
instance.interceptors.request.use(async (request) => {
    const token = tokenStorageService.getAccessToken()
    if (token) request.headers.Authorization = `Bearer ${token}`

    return request
})

export default instance
