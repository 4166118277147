import type { Theme } from '@material-ui/core'
import { experimentalStyled } from '@material-ui/core/styles'
import type { SxProps } from '@material-ui/system'
import type { FC } from 'react'

interface LogoProps {
    sx?: SxProps<Theme>
}

const LogoRoot = experimentalStyled('svg')``

const Logo: FC<LogoProps> = (props) => (
    <LogoRoot
        height="50"
        version="1.1"
        viewBox="0 50 300 450"
        width="50"
        {...props}
    >
        <g>
            <g>
                <path
                    style={{ fill: '#FFFFFF' }}
                    d="M116.7,381.8c0,44.6-20.6,70.5-66.2,70.5c-20.6,0-36.2-4.3-41.2-5.3v-17c3.7,1,17.6,6.3,35.6,6.3
			c40.2,0,55.2-16,55.2-53.5v-29.9h-0.7c-3.3,13.3-15.3,32.9-43.2,32.9C11.3,385.8,0,348.6,0,306c0-38.6,15-82.1,55.5-82.1
			c28.6,0,39.9,19.3,43.9,32.9h0.7v-29.3h16.6V381.8z M56.9,369.9c29.6,0,42.9-36.6,42.9-65.2c0-26.9-12-64.8-42.6-64.8
			C28.6,239.9,20,280.4,20,309C20,330.3,23.6,369.9,56.9,369.9z"
                />
                <path
                    style={{ fill: '#FFFFFF' }}
                    d="M174.9,401.1c0,34.9-19.3,50.2-53.2,50.2c-1.7,0-3.3-0.3-5-0.3v-16.6c2.3,0.7,4.7,1,7.3,1
			c22.6,0,34.2-9,34.2-35.9V227.5h16.6V401.1z M154.3,156.4h24.6v26.9h-24.6V156.4z"
                />
            </g>
            <g>
                <path
                    style={{ fill: '#FFFFFF' }}
                    d="M214.4,257.5h0.7c3.7-12.3,15-33.6,44.2-33.6c39.2,0,54.9,41.9,54.9,84.5c0,45.2-18.6,81.1-61.2,81.1
			c-38.6,0-55.2-30.6-55.2-68.5V156.4h16.6V257.5z M253,373.5c30.3,0,41.2-33.3,41.2-65.5c0-27.3-8.6-68.2-36.9-68.2
			s-42.2,38.6-42.2,70.8C215.1,337.6,221.8,373.5,253,373.5z"
                />
            </g>
        </g>
    </LogoRoot>
)

export default Logo
