import axios from '../lib/axios'
import { Account } from '../types/account'
import Team from '../types/Team'

class AuthApi {
    async login({ email, password }): Promise<string> {
        const response = await axios.post<string>(
            'auth/login',
            {
                email,
                password,
            },
            {
                withCredentials: true,
            }
        )
        return response.data
    }

    async logout(): Promise<void> {
        await axios.post<string>(
            'auth/revoke-token',
            {},
            {
                withCredentials: true,
            }
        )
    }

    async forgotPassword(email: string): Promise<void> {
        await axios.post<void>('auth/forgot-password', { email })
    }

    async resetPassword({ token, userId, password }): Promise<void> {
        await axios.post<void>('auth/reset-password', {
            token,
            userId,
            password,
        })
    }

    async teamInfo(): Promise<Team> {
        const response = await axios.get<Team>('auth/team-info')
        return response.data
    }

    async impersonatorInfo(): Promise<Account> {
        const response = await axios.get<Account>('auth/impersonator-info')
        return response.data
    }

    async selectUser(userId: string): Promise<string> {
        const response = await axios.post<string>(
            'auth/select-user',
            {
                userId,
            },
            {
                withCredentials: true,
            }
        )
        return response.data
    }

    async selectTeam(teamId: string): Promise<string> {
        const response = await axios.post<string>(
            'auth/select-team',
            {
                teamId,
            },
            {
                withCredentials: true,
            }
        )
        return response.data
    }

    async deselectUser(): Promise<string> {
        const response = await axios.post<string>('auth/deselect-user', null, {
            withCredentials: true,
        })
        return response.data
    }
}

export const authApi = new AuthApi()
