import { Box, Button, Container, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import type { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'

const AuthorizationRequired: FC = () => {
    const theme = useTheme()
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <>
            <Helmet>
                <title>Fehler: Autorisierung erforderlich</title>
            </Helmet>
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    minHeight: '100%',
                    px: 3,
                    py: '80px',
                }}
            >
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant={mobileDevice ? 'h4' : 'h1'}
                    >
                        401: Autorisierung erforderlich
                    </Typography>
                    <Typography
                        align="center"
                        color="textSecondary"
                        sx={{ mt: 0.5 }}
                        variant="subtitle2"
                    >
                        Sie besitzen nicht die erforderlichen Rechte diese Seite
                        aufzurufen. Falls dies ein Fehler ist, kontaktieren Sie
                        den Support.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6,
                        }}
                    >
                        <Box
                            alt="Under development"
                            component="img"
                            src={`/static/error/error401_${theme.palette.mode}.svg`}
                            sx={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: 400,
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6,
                        }}
                    >
                        <Button
                            color="primary"
                            component={RouterLink}
                            to="/"
                            variant="outlined"
                        >
                            Zurück zu Home
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default AuthorizationRequired
