const TokenStorageService = (() => {
    function setAccessToken(token: string): void {
        localStorage.setItem('access_token', token)
    }

    function getAccessToken(): string {
        return localStorage.getItem('access_token')
    }

    function clearToken(): void {
        localStorage.removeItem('access_token')
    }

    return {
        setAccessToken,
        getAccessToken,
        clearToken,
    }
})()

export default TokenStorageService
