import {
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
} from '@material-ui/core'
import UploadFileIcon from '@material-ui/icons/UploadFile'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const FormFileField: FC<TextFieldProps> = (props) => {
    const { name, ...rest } = props
    const { control, setValue } = useFormContext()
    const inputFile = useRef<HTMLInputElement | null>(null)

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        if (file)
            setValue(name, file, {
                shouldValidate: true,
            })
    }

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                }) => (
                    <TextField
                        name={name}
                        error={!!error}
                        helperText={error?.message}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value?.name || ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={() =>
                                            inputFile.current.click()
                                        }
                                    >
                                        <UploadFileIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        {...rest}
                    />
                )}
            />
            <input
                type="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={handleFileChange.bind(this)}
            />
        </>
    )
}

FormFileField.propTypes = {
    name: PropTypes.string.isRequired,
}

export default FormFileField
