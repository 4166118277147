import { TextField, TextFieldProps } from '@material-ui/core'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import InputMask from 'react-input-mask'

interface FormMaskedTextFieldProps {
    mask: any
}

const FormMaskedTextField: FC<FormMaskedTextFieldProps & TextFieldProps> = (
    props
) => {
    const { mask, name, ...rest } = props
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
            }) => (
                <InputMask
                    mask={mask}
                    value={value}
                    disabled={false}
                    onBlur={onBlur}
                    onChange={onChange}
                >
                    <TextField
                        name={name}
                        error={!!error}
                        helperText={error?.message}
                        {...rest}
                    />
                </InputMask>
            )}
        />
    )
}

FormMaskedTextField.propTypes = {
    mask: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
}

export default FormMaskedTextField
