import { Box, Button, FormHelperText, TextField } from '@material-ui/core'
import { Formik } from 'formik'
import type { FC } from 'react'
import * as Yup from 'yup'
import useAuth from '../../hooks/useAuth'
import useMounted from '../../hooks/useMounted'

const LoginEditForm: FC = (props) => {
    const mounted = useMounted()
    const { login } = useAuth() as any

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email('Keine gültige E-Mail')
                    .max(255)
                    .required('E-Mail ist erforderlich'),
                password: Yup.string()
                    .max(255)
                    .required('Passwort ist erforderlich'),
            })}
            onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
            ): Promise<void> => {
                try {
                    await login(values.email, values.password)

                    if (mounted.current) {
                        setStatus({ success: true })
                        setSubmitting(false)
                    }
                } catch (err) {
                    console.error(err)
                    if (mounted.current) {
                        setStatus({ success: false })
                        setErrors({ submit: err.response.data })
                        setSubmitting(false)
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }): JSX.Element => (
                <form noValidate onSubmit={handleSubmit} {...props}>
                    <TextField
                        autoFocus
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="E-Mail Addresse"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Passwort"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                    />
                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            Log In
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    )
}

export default LoginEditForm
