import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import type { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import { useRoutes } from 'react-router-dom'
// import SettingsDrawer from './components/SettingsDrawer'
import SplashScreen from './components/SplashScreen'
import { ConfirmationProvider } from './contexts/ConfirmationContext'
import useAuth from './hooks/useAuth'
import useScrollReset from './hooks/useScrollReset'
import useSettings from './hooks/useSettings'
import routes from './routes'
import { createCustomTheme } from './theme'

const App: FC = () => {
    const content = useRoutes(routes)
    const { settings } = useSettings()
    const auth = useAuth()

    useScrollReset()

    const theme = createCustomTheme({
        responsiveFontSizes: settings.responsiveFontSizes,
        roundedCorners: settings.roundedCorners,
        theme: settings.theme,
    })

    LicenseInfo.setLicenseKey(
        '52e06171b4cd04afe97c5a2bf8ee4194T1JERVI6NDEwMjUsRVhQSVJZPTE2ODA1MzQzMTgwMDAsS0VZVkVSU0lPTj0x'
    )
    return (
        <ThemeProvider theme={theme}>
            <ConfirmationProvider>
                <CssBaseline />
                <Toaster position="top-center" />
                {/* <SettingsDrawer /> */}
                {auth.isInitialized ? content : <SplashScreen />}
            </ConfirmationProvider>
        </ThemeProvider>
    )
}

export default App
