import axios from 'axios'
import handleEmptyStrings from '../utils/stringsToNull'
import TokenService from './tokenService'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
})

instance.interceptors.request.use((originalRequest) => {
    handleEmptyStrings(originalRequest.data)
    return originalRequest
})

instance.interceptors.request.use(async (request) => {
    const token = await TokenService.getToken()
    if (token) request.headers.Authorization = `Bearer ${token}`

    return request
})

export default instance
