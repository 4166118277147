import { lazy, Suspense } from 'react'
import type { PartialRouteObject } from 'react-router'
import { Navigate, Outlet } from 'react-router-dom'
import AuthGuard from './components/AuthGuard'
import DashboardLayout from './components/dashboard/DashboardLayout'
import GuestGuard from './components/GuestGuard'
import LoadingScreen from './components/LoadingScreen'
import MainLayout from './components/MainLayout'
import TeamUserEdit from './pages/dashboard/TeamUserEdit'

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    )

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')))
const PasswordRecovery = Loadable(
    lazy(() => import('./pages/authentication/PasswordRecovery'))
)
const PasswordReset = Loadable(
    lazy(() => import('./pages/authentication/PasswordReset'))
)

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/AccountEdit')))
const ParticipantEdit = Loadable(
    lazy(() => import('./pages/dashboard/ParticipantEdit'))
)
const ParticipantList = Loadable(
    lazy(() => import('./pages/dashboard/ParticipantList'))
)
const SchoolEdit = Loadable(lazy(() => import('./pages/dashboard/SchoolEdit')))
const SchoolList = Loadable(lazy(() => import('./pages/dashboard/SchoolList')))
const CompanyEdit = Loadable(
    lazy(() => import('./pages/dashboard/CompanyEdit'))
)
const CompanyList = Loadable(
    lazy(() => import('./pages/dashboard/CompanyList'))
)
const ConsultingCenterEdit = Loadable(
    lazy(() => import('./pages/dashboard/ConsultingCenterEdit'))
)
const ConsultingCenterList = Loadable(
    lazy(() => import('./pages/dashboard/ConsultingCenterList'))
)
const ContactList = Loadable(
    lazy(() => import('./pages/dashboard/ContactList'))
)
const Matchbox = Loadable(lazy(() => import('./pages/dashboard/Matchbox')))
const AZE = Loadable(lazy(() => import('./pages/dashboard/AZE')))
const ResubmissionEdit = Loadable(
    lazy(() => import('./pages/dashboard/ResubmissionEdit'))
)
const UserList = Loadable(lazy(() => import('./pages/dashboard/UserList')))
const UserEdit = Loadable(lazy(() => import('./pages/dashboard/UserEdit')))
const TeamEdit = Loadable(lazy(() => import('./pages/dashboard/TeamEdit')))
const RollEdit = Loadable(lazy(() => import('./pages/dashboard/RoleEdit')))
const SystemEdit = Loadable(lazy(() => import('./pages/dashboard/SystemEdit')))

const Forum = Loadable(lazy(() => import('./pages/dashboard/Forum')))

const RepresentationEdit = Loadable(
    lazy(() => import('./pages/dashboard/RepresentationEdit'))
)
const BaseDataEdit = Loadable(
    lazy(() => import('./pages/dashboard/BaseDataEdit'))
)
const BaseDataActivityEdit = Loadable(
    lazy(() => import('./pages/dashboard/BaseDataActivityEdit'))
)
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')))

// Error pages

const AuthorizationRequired = Loadable(
    lazy(() => import('./pages/AuthorizationRequired'))
)
const NotFound = Loadable(lazy(() => import('./pages/NotFound')))
const ServerError = Loadable(lazy(() => import('./pages/ServerError')))

// Sick day page
const SickDay = Loadable(lazy(() => import('./pages/SickDay')))

// Add Permission here (2)
// Search for "Add Permission here" to find all places where permission has to be set
// They are numbered to guide you through the steps

const routes: PartialRouteObject[] = [
    {
        path: 'authentication',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                ),
            },
            {
                path: 'password-recovery',
                element: <PasswordRecovery />,
            },
            {
                path: 'reset-password',
                element: <PasswordReset />,
            },
        ],
    },
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '/',
                element: <Overview />,
            },
            {
                path: 'account',
                element: <Account />,
            },
            {
                path: 'participants',
                element: (
                    <AuthGuard permissions={['participant']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <ParticipantList />,
                    },
                    {
                        path: '/new',
                        element: <ParticipantEdit />,
                    },
                    {
                        path: ':participantId/edit',
                        element: <ParticipantEdit />,
                    },
                ],
            },
            {
                path: 'schools',
                element: (
                    <AuthGuard permissions={['school']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <SchoolList />,
                    },
                    {
                        path: '/new',
                        element: <SchoolEdit />,
                    },
                    {
                        path: ':schoolId/edit',
                        element: <SchoolEdit />,
                    },
                ],
            },
            {
                path: 'companies',
                element: (
                    <AuthGuard permissions={['company']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <CompanyList />,
                    },
                    {
                        path: '/new',
                        element: <CompanyEdit />,
                    },
                    {
                        path: ':companyId/edit',
                        element: <CompanyEdit />,
                    },
                ],
            },
            {
                path: 'consulting-centers',
                element: (
                    <AuthGuard permissions={['consulting-center']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <ConsultingCenterList />,
                    },
                    {
                        path: '/new',
                        element: <ConsultingCenterEdit />,
                    },
                    {
                        path: ':consultingCenterId/edit',
                        element: <ConsultingCenterEdit />,
                    },
                ],
            },
            {
                path: 'contacts',
                element: (
                    <AuthGuard permissions={['contact']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <ContactList />,
                    },
                ],
            },
            {
                path: 'resubmissions',
                element: (
                    <AuthGuard permissions={['resubmission']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <ResubmissionEdit />,
                    },
                ],
            },
            {
                path: 'matchbox',
                element: (
                    <AuthGuard permissions={['matchbox']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <Matchbox />,
                    },
                ],
            },
            {
                path: 'aze',
                element: (
                    <AuthGuard permissions={['aze']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <AZE />,
                    },
                ],
            },
            {
                path: 'forum',
                element: (
                    <AuthGuard permissions={['forum']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <Forum />,
                    },
                    {
                        path: '/:folder1',
                        element: <Forum />,
                    },
                    {
                        path: '/:folder1/:folder2',
                        element: <Forum />,
                    },
                    {
                        path: '/:folder1/:folder2/:folder3',
                        element: <Forum />,
                    },
                    {
                        path: '/:folder1/:folder2/:folder3/:folder4',
                        element: <Forum />,
                    },
                ],
            },
            {
                path: 'users',
                element: (
                    <AuthGuard permissions={['management.user']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <UserList />,
                    },
                    {
                        path: '/new',
                        element: <UserEdit />,
                    },
                    {
                        path: ':userId/edit',
                        element: <UserEdit />,
                    },
                ],
            },
            {
                path: 'team-users',
                element: (
                    <AuthGuard permissions={['management.team-user']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <TeamUserEdit />,
                    },
                ],
            },
            {
                path: 'representations',
                element: (
                    <AuthGuard permissions={['management.representation']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <RepresentationEdit />,
                    },
                ],
            },
            {
                path: 'teams',
                element: (
                    <AuthGuard permissions={['management.team']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <TeamEdit />,
                    },
                ],
            },
            {
                path: 'roles',
                element: (
                    <AuthGuard permissions={['management.role']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <RollEdit />,
                    },
                ],
            },
            {
                path: 'system',
                element: (
                    <AuthGuard permissions={['system']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <SystemEdit />,
                    },
                ],
            },
            {
                path: 'base-data',
                element: (
                    <AuthGuard permissions={['base-data']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <BaseDataEdit />,
                    },
                ],
            },
            {
                path: 'base-data-activity',
                element: (
                    <AuthGuard permissions={['base-data']}>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    {
                        path: '/',
                        element: <BaseDataActivityEdit />,
                    },
                ],
            },
        ],
    },
    {
        path: '*',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <Navigate to="/dashboard" replace />,
            },
            {
                path: 'sick-day',
                element: <SickDay />,
            },
            {
                path: '401',
                element: <AuthorizationRequired />,
            },
            {
                path: '404',
                element: <NotFound />,
            },
            {
                path: '500',
                element: <ServerError />,
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
]

export default routes
