import { Box, Card } from '@material-ui/core'
import { DataGrid, deDE } from '@mui/x-data-grid'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { useMemo, useState } from 'react'
import type Team from '../../../types/Team'
import type TeamUser from '../../../types/TeamUser'
// import { FormAutoComplete } from '../../form'
import { Autocomplete, TextField } from '@mui/material'

interface TeamOverviewEditFormProps {
    teamUsers: TeamUser[]
    teams: Team[]
}

const TeamUserList: FC<TeamOverviewEditFormProps> = (props) => {
    const { teamUsers, teams } = props
    const [teamUserFiltered, setTeamUserFiltered] = useState<TeamUser[]>([])

    const columns = useMemo(
        () => [
            { field: 'userName', headerName: 'Benutzer', flex: 1 },
            { field: 'roleName', headerName: 'Rolle', flex: 1 },
        ],
        []
    )

    const setTeamFilter = (val) => {
        if (val === null) return setTeamUserFiltered([])
        return setTeamUserFiltered(teamUsers.filter((x) => x.teamId === val.id))
    }

    if (!teamUserFiltered) {
        return null
    }
    return (
        <>
            <Box mt={2}>
                <Card>
                    <Box sx={{ height: 650, width: '100%', px: 2 }}>
                        <Autocomplete
                            disablePortal
                            options={teams}
                            getOptionLabel={(option): string => {
                                return `${option.name}`
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Team" />
                            )}
                            onChange={(event, value) => setTeamFilter(value)}
                            sx={{ py: 2 }}
                        />
                        <DataGrid
                            localeText={
                                deDE.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            rows={teamUserFiltered}
                            columns={columns}
                            sx={{
                                border: 0,
                                pb: 4,
                            }}
                        />
                    </Box>
                </Card>
            </Box>
        </>
    )
}

TeamUserList.propTypes = {
    teamUsers: PropTypes.array.isRequired,
    teams: PropTypes.array.isRequired,
}

export default TeamUserList
