import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface FormCheckBoxProps extends CheckboxProps {
    label?: string
}

const FormCheckBox: FC<FormCheckBoxProps> = (props) => {
    const { name, label, onChange, ...rest } = props
    const { control } = useFormContext()

    const renderCheckbox = (onChangeRHF, onBlur, value) => {
        if (label) {
            return (
                <FormControlLabel
                    sx={{ height: '48px' }}
                    control={
                        <Checkbox
                            onBlur={onBlur}
                            onChange={(event: any) => {
                                onChangeRHF(event)
                                onChange?.(event)
                            }}
                            checked={value}
                            {...rest}
                        />
                    }
                    label={label || ''}
                />
            )
        }
        return (
            <Checkbox
                sx={{ height: '48px' }}
                onBlur={onBlur}
                onChange={(event: any) => {
                    onChangeRHF(event)
                    onChange?.(event)
                }}
                checked={value}
                {...rest}
            />
        )
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange: onChangeRHF, onBlur, value } }) =>
                renderCheckbox(onChangeRHF, onBlur, value)
            }
        />
    )
}

FormCheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
}

export default FormCheckBox
