import { Box, Button, Paper, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import type { FC } from 'react'

type Variant = 'danger' | 'info'

export interface ConfirmationOptions {
    catchOnCancel?: boolean
    variant?: Variant
    title?: string
    description?: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
    open: boolean
    onSubmit: () => void
    onClose: () => void
}

function simpleHash(str: string): string {
    let hash = 0
    if (str.length === 0) return hash.toString()
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = hash * 31 + char
    }
    return hash.toString()
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
    open,
    title,
    variant,
    description,
    onSubmit,
    onClose,
}) => (
    <Dialog open={open}>
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                p: 0,
            }}
        >
            <Paper elevation={12}>
                <Box
                    sx={{
                        display: 'flex',
                        pb: 2,
                        pt: 3,
                        px: 3,
                    }}
                >
                    <Box>
                        <Typography color="textPrimary" variant="h5">
                            {title}
                        </Typography>
                        <Typography
                            color="textSecondary"
                            component="span"
                            sx={{ mt: 1 }}
                            variant="body2"
                            display="inline-block"
                        >
                            {description?.split('\n').map((str) => (
                                <div key={simpleHash(str)}>{str}</div>
                            ))}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        px: 3,
                        py: 1.5,
                    }}
                >
                    {variant === 'danger' && (
                        <>
                            <Button
                                sx={{ mr: 2 }}
                                color="primary"
                                variant="outlined"
                                onClick={onSubmit}
                            >
                                Ja
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onClose}
                                autoFocus
                            >
                                Abbrechen
                            </Button>
                        </>
                    )}

                    {variant === 'info' && (
                        <Button color="primary" onClick={onSubmit}>
                            OK
                        </Button>
                    )}
                </Box>
            </Paper>
        </Box>
    </Dialog>
)

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    variant: PropTypes.oneOf<Variant>(['danger', 'info']),
    description: PropTypes.string,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
}

export default ConfirmationDialog
