import {
    Box,
    Card,
    CardContent,
    Container,
    Divider,
    Link,
    Typography,
} from '@material-ui/core'
import type { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'
import { LoginEditForm } from '../../components/authentication'
import Logo from '../../components/Logo'

const Login: FC = () => {
    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <Container maxWidth="sm" sx={{ py: '80px' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 8,
                        }}
                    >
                        <RouterLink to="/">
                            <Logo
                                sx={{
                                    height: 40,
                                    width: 40,
                                }}
                            />
                        </RouterLink>
                    </Box>
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3,
                                }}
                            >
                                <div>
                                    <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h4"
                                    >
                                        Log in
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        Melden Sie sich auf der internen
                                        Plattform an
                                    </Typography>
                                </div>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3,
                                }}
                            >
                                <LoginEditForm />
                            </Box>
                            <Divider sx={{ my: 3 }} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    mt: 2,
                                }}
                            >
                                <Link
                                    color="textSecondary"
                                    component={RouterLink}
                                    sx={{ mt: 1 }}
                                    to="/authentication/password-recovery"
                                    variant="body2"
                                >
                                    Passwort vergessen?
                                </Link>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginLeft: 'auto',
                                        gap: 2,
                                    }}
                                >
                                    <Link
                                        target="_blank"
                                        fontSize={12}
                                        sx={{ mt: 1, mb: 0 }}
                                        href="https://www.kes-soft.de"
                                    >
                                        KES Softwareentwicklung 2023
                                    </Link>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    )
}

export default Login
