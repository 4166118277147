import type { Theme } from '@material-ui/core'
import { Box, Divider, Drawer, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ContactPhone from '@material-ui/icons/ContactPhone'
import Domain from '@material-ui/icons/Domain'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { resubmissionsApi } from 'src/api/resubmissionsApi'
import useMounted from 'src/hooks/useMounted'
import { Totals } from 'src/types/Resubmission'
import useAuth from '../../hooks/useAuth'
import AcademicCapIcon from '../../icons/AcademicCap'
import Adjustments from '../../icons/Adjustments'
import BriefcaseIcon from '../../icons/Briefcase'
import ClipboardListcon from '../../icons/ClipboardList'
import ClockIcon from '../../icons/Clock'
import CogIcon from '../../icons/Cog'
import DocumentTextIcon from '../../icons/DocumentText'
import FolderOpenIcon from '../../icons/FolderOpen'
import SelectorIcon from '../../icons/Selector'
import TemplateIcon from '../../icons/Template'
import UsersIcon from '../../icons/Users'
import type Team from '../../types/Team'
import deepCopy from '../../utils/deepCopy'
import NavSection from '../NavSection'
import Scrollbar from '../Scrollbar'
import DashboardSidebarPopover from './DashboardSidebarPopover'

interface DashboardSidebarProps {
    onMobileClose: () => void
    openMobile: boolean
}

// Add Permission here (3)
// Search for "Add Permission here" to find all places where permission has to be set
// They are numbered to guide you through the steps

const navigationSections = [
    {
        title: 'Beratung',
        items: [
            {
                title: 'Teilnehmende',
                path: '/dashboard/participants',
                icon: <UsersIcon fontSize="small" />,
                permissions: ['participant'],
            },
            {
                title: 'Schulen',
                path: '/dashboard/schools',
                icon: <Domain fontSize="small" />,
                permissions: ['school'],
            },
            {
                title: 'Betriebe',
                path: '/dashboard/companies',
                icon: <BriefcaseIcon fontSize="small" />,
                permissions: ['company'],
            },
            {
                title: 'Beratungsstellen',
                path: '/dashboard/consulting-centers',
                icon: <AcademicCapIcon fontSize="small" />,
                permissions: ['consulting-center'],
            },
            {
                title: 'Wiedervorlagen',
                path: '/dashboard/resubmissions',
                icon: <ClockIcon fontSize="small" />,
                permissions: ['resubmission'],
            },
        ],
    },
    {
        title: 'Auswertungen',
        items: [
            {
                title: 'Berichte',
                path: '/dashboard/matchbox',
                icon: <DocumentTextIcon fontSize="small" />,
                permissions: ['matchbox'],
            },
        ],
    },
    {
        title: 'Adressverwaltung',
        items: [
            {
                title: 'Adressdatenbank',
                path: '/dashboard/contacts',
                icon: <ContactPhone fontSize="small" />,
                permissions: ['contact'],
            },
        ],
    },
    {
        title: 'Zeiterfassung',
        items: [
            {
                title: 'AZE',
                path: '/dashboard/aze',
                icon: <ClipboardListcon fontSize="small" />,
                permissions: ['aze'],
            },
        ],
    },
    {
        title: 'Forum',
        items: [
            {
                title: 'Forum',
                path: '/dashboard/forum',
                icon: <FolderOpenIcon fontSize="small" />,
                permissions: ['forum'],
            },
        ],
    },
    {
        title: 'Administration',
        items: [
            {
                title: 'Verwaltung',
                path: '/dashboard/admin',
                icon: <CogIcon fontSize="small" />,
                permissions: ['management'],
                children: [
                    {
                        title: 'Benutzer',
                        path: '/dashboard/users',
                        permissions: ['management.user'],
                    },
                    {
                        title: 'Rollenübersicht Teams',
                        path: '/dashboard/team-users',
                        permissions: ['management.team-user'],
                    },
                    {
                        title: 'Vertretungen',
                        path: '/dashboard/representations',
                        permissions: ['management.representation'],
                    },
                    {
                        title: 'Teams',
                        path: '/dashboard/teams',
                        permissions: ['management.team'],
                    },
                    {
                        title: 'Rollen',
                        path: '/dashboard/roles',
                        permissions: ['management.role'],
                    },
                ],
            },
            {
                title: 'Stammdaten',
                path: '/dashboard/base-data',
                icon: <Adjustments fontSize="small" />,
                permissions: ['base-data'],
                children: [
                    {
                        title: 'Basisdaten',
                        path: '/dashboard/base-data',
                    },
                    {
                        title: 'Anschluss Art/Form',
                        path: '/dashboard/base-data-activity',
                    },
                ],
            },
            {
                title: 'System',
                path: '/dashboard/system',
                icon: <TemplateIcon fontSize="small" />,
                permissions: ['system'],
            },
        ],
    },
]

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const mounted = useMounted()
    const { onMobileClose, openMobile } = props
    const location = useLocation()
    const { user, team, ...auth } = useAuth()
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    const popoverRef = useRef<HTMLButtonElement | null>(null)
    const [openPopover, setOpenPopover] = useState<boolean>(false)
    const [totals, setTotals] = useState<Totals | null>()
    const [sections, setSections] = useState<any[]>(navigationSections)

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose()
        }
    }, [location.pathname])

    useEffect(() => {
        if (!totals || totals.count === 0) return

        const sectionsClone = sections.map((section) => deepCopy(section))
        const consultingSection = sectionsClone.find(
            (x) => x.title === 'Beratung'
        )
        const resubmissionItem = consultingSection.items.find(
            (x) => x.title === 'Wiedervorlagen'
        )
        resubmissionItem.info = totals.count.toString()

        setSections(sectionsClone)
    }, [totals])

    const getResubmissions = useCallback(async () => {
        try {
            const data = await resubmissionsApi.getTotals(false)
            if (mounted.current) {
                setTotals(data)
            }
        } catch (err) {
            console.error(err)
        }
    }, [mounted])

    useEffect(() => {
        getResubmissions()
    }, [getResubmissions])

    const handleSelectTeam = async (selectedTeam: Team): Promise<void> => {
        if (selectedTeam.id !== team?.id) await auth.selectTeam(selectedTeam.id)
    }

    const handleOpenPopover = (): void => {
        setOpenPopover(true)
    }

    const handleClosePopover = (): void => {
        setOpenPopover(false)
    }

    const authFilter = (navSections): any => {
        const navSectionsClone = navSections.map((section) => deepCopy(section))
        navSectionsClone.map(
            (section) => (section.items = filterNavItems(section.items))
        )
        return navSectionsClone.filter((section) => section.items.length !== 0)
    }

    const filterNavItems = (navItems) =>
        navItems.filter((o) => {
            if (o.children && Array.isArray(o.children)) {
                if (checkPermission(o.permissions, auth.permissions)) {
                    o.children = filterNavItems(o.children)
                    return true
                }
                return false
            }
            return checkPermission(o.permissions, auth.permissions)
        })

    const checkPermission = (requiredPermissions, suppliedPermissions) =>
        !requiredPermissions ||
        requiredPermissions.length === 0 ||
        requiredPermissions.every((r) => suppliedPermissions?.includes(r))

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Scrollbar options={{ suppressScrollX: true }}>
                    <Box sx={{ p: 2 }}>
                        <Box
                            onClick={handleOpenPopover}
                            ref={popoverRef}
                            sx={{
                                alignItems: 'center',
                                backgroundColor: 'background.default',
                                borderRadius: 1,
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                                display: 'flex',
                                overflow: 'hidden',
                                p: 2,
                                height: 80,
                            }}
                        >
                            <div>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    {team?.name || 'Kein Team'}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {user.firstName} {user.lastName}
                                </Typography>
                            </div>
                            <SelectorIcon
                                sx={{
                                    color: 'neutral.500',
                                    width: 14,
                                    height: 14,
                                }}
                            />
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                        {authFilter(sections).map((section) => (
                            <NavSection
                                key={section.title}
                                pathname={location.pathname}
                                sx={{
                                    '& + &': {
                                        mt: 1, // Original Wert = 3
                                    },
                                }}
                                {...section}
                            />
                        ))}
                    </Box>
                </Scrollbar>
            </Box>
            {auth.assignedTeams && (
                <DashboardSidebarPopover
                    anchorEl={popoverRef.current}
                    displayField="name"
                    valueField="id"
                    items={auth.assignedTeams}
                    onSelect={handleSelectTeam}
                    onClose={handleClosePopover}
                    open={openPopover}
                />
            )}
        </>
    )

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.paper',
                        height: 'calc(100% - 64px) !important',
                        top: '64px !Important',
                        width: 240,
                    },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        )
    }

    return (
        <Drawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    width: 240,
                },
            }}
            variant="temporary"
        >
            {content}
        </Drawer>
    )
}

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
}

export default DashboardSidebar
