import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import Login from '../pages/authentication/Login'
import AuthorizationRequired from '../pages/AuthorizationRequired'

interface AuthGuardProps {
    children: ReactNode
    permissions?: string[]
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children, permissions } = props
    const auth = useAuth()
    const location = useLocation()
    const [requestedLocation, setRequestedLocation] = useState(null)

    if (!auth.isAuthenticated) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname)
        }

        return <Login />
    }

    // Here check the user permissions
    const canView =
        !permissions ||
        permissions.length === 0 ||
        permissions.every((r) => auth.permissions?.includes(r))

    if (!canView) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname)
        }

        return <AuthorizationRequired />
    }

    // This is done so that in case the route changes by any chance through other
    // means between the moment of request and the render we navigate to the initially
    // requested route.
    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <>{children}</>
}

AuthGuard.propTypes = {
    children: PropTypes.node,
    permissions: PropTypes.arrayOf(PropTypes.string),
}

export default AuthGuard
