import axios from '../lib/axios'
import type Team from '../types/Team'
import type TeamUser from '../types/TeamUser'

class TeamsApi {
    async getAll(): Promise<Team[]> {
        const response = await axios.get<Team[]>('teams')
        // console.log('response.data', response.data)
        return response.data
    }

    async getTeamById(teamId: string): Promise<Team> {
        const response = await axios.get<Team>(`teams/${teamId}`)
        return response.data
    }

    async create(team: Team): Promise<Team> {
        const response = await axios.post<Team>('teams', team)
        return response.data
    }

    async update(team: Team): Promise<Team> {
        const response = await axios.put<Team>(`teams/${team.id}`, team)
        return response.data
    }

    async delete(team: Team): Promise<Team[]> {
        const response = await axios.delete<Team[]>(`teams/${team.id}`)
        return response.data
    }

    async getUsersByTeamId(): Promise<TeamUser[]> {
        const response = await axios.get<TeamUser[]>('teamUsers')
        return response.data
    }
}

export const teamsApi = new TeamsApi()
