import { Grid } from '@material-ui/core'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { FC, useEffect, useState } from 'react'
import type Team from '../../../types/Team'
import deepCopy from '../../../utils/deepCopy'
import TeamEditForm from './TeamEditForm'

interface TeamListProps {
    teams: Team[]
    onFormDirtyChange?: (value: boolean) => void
    onSave?: (value: Team) => void
    onDelete?: (value: Team) => void
}

const TeamList: FC<TeamListProps> = (props) => {
    const { teams, onFormDirtyChange, onSave, onDelete } = props

    const [dirtyForms, setDirtyForms] = useState({})

    const handleFormDirtyChange = (id: string, value: boolean) => {
        if (value) setDirtyForms({ ...dirtyForms, [id]: value })
        else if (dirtyForms[id]) {
            const copy = deepCopy(dirtyForms)
            delete copy[id]
            setDirtyForms(copy)
        }
    }

    useEffect(() => {
        if (_.isEmpty(dirtyForms)) onFormDirtyChange(false)
        else onFormDirtyChange(true)
    }, [_.isEmpty(dirtyForms)])

    if (!teams) {
        return null
    }
    return (
        <Grid container spacing={1} sx={{ mt: 0 }}>
            {teams.map((team) => (
                <Grid item key={team.id || 0} lg={12}>
                    <TeamEditForm
                        team={team}
                        onFormDirtyChange={(value) =>
                            handleFormDirtyChange(team.id, value)
                        }
                        onSave={onSave}
                        onDelete={onDelete}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

TeamList.propTypes = {
    // @ts-ignore
    teams: PropTypes.array.isRequired,
    onFormDirtyChange: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
}

export default TeamList
