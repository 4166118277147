import axios from '../lib/axios'
import { Account } from '../types/account'
import Team from '../types/Team'

class AccountApi {
    async me(): Promise<Account> {
        const response = await axios.get<Account>('account')
        return response.data
    }

    async assignedTeams(): Promise<Team[]> {
        const response = await axios.get<Team[]>('account/assigned-teams')
        return response.data
    }

    async assignedUsers(): Promise<Account[]> {
        const response = await axios.get<Account[]>('account/assigned-users')
        return response.data
    }
}

export const accountApi = new AccountApi()
