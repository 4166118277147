import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography,
} from '@material-ui/core'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import UserIcon from '../../icons/User'

const AccountPopover: FC = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const { user, team, logout } = useAuth()
    const navigate = useNavigate()
    const [open, setOpen] = useState<boolean>(false)

    const handleOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    const handleLogout = async (): Promise<void> => {
        try {
            handleClose()
            await logout()
            navigate('/')
        } catch (err) {
            console.error(err)
            toast.error('Abmelden nicht möglich.')
        }
    }

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <Avatar
                    src={null}
                    sx={{
                        height: 32,
                        width: 32,
                    }}
                />
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 240 },
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography color="textPrimary" variant="subtitle2">
                        {user.firstName} {user.lastName}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                        {team?.name || 'Kein Team'}
                    </Typography>
                </Box>
                <Divider />
                <Box sx={{ mt: 2 }}>
                    <MenuItem component={RouterLink} to="/dashboard/account">
                        <ListItemIcon>
                            <UserIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Account
                                </Typography>
                            }
                        />
                    </MenuItem>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={handleLogout}
                        variant="outlined"
                    >
                        Logout
                    </Button>
                </Box>
            </Popover>
        </>
    )
}

export default AccountPopover
