import {
    Box,
    Breadcrumbs,
    Container,
    Grid,
    Typography,
} from '@material-ui/core'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { teamsApi } from '../../api/teamsApi'
import { TeamUserList } from '../../components/dashboard/team'
import useMounted from '../../hooks/useMounted'
import useSettings from '../../hooks/useSettings'
import ChevronRightIcon from '../../icons/ChevronRight'
import type Team from '../../types/Team'
import type TeamUser from '../../types/TeamUser'

const TeamUserEdit: FC = () => {
    const mounted = useMounted()
    const { settings } = useSettings()
    const [teamUsers, setTeamUsers] = useState<TeamUser[]>([])
    const [teams, setTeams] = useState<Team[] | null>(null)

    const getTeamUsers = useCallback(async () => {
        try {
            const data = await teamsApi.getUsersByTeamId()

            if (mounted.current) {
                setTeamUsers(data)
            }
        } catch (err) {
            console.error(err)
        }
    }, [mounted])

    const getTeamData = useCallback(async () => {
        try {
            const data = await teamsApi.getAll()

            if (mounted.current) {
                setTeams(data)
            }
        } catch (err) {
            console.error(err)
        }
    }, [mounted])

    useEffect(() => {
        getTeamUsers()
    }, [getTeamUsers])

    useEffect(() => {
        getTeamData()
    }, [getTeamData])

    if (!teams || !teamUsers) {
        return null
    }

    return (
        <>
            <Helmet>
                <title>Dashboard: Rollenübersicht Teams</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 4,
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                Rollenübersicht Teams
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={
                                    <ChevronRightIcon fontSize="small" />
                                }
                                sx={{ mt: 1 }}
                            >
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Rollenübersicht Teams
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <TeamUserList teamUsers={teamUsers} teams={teams} />
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default TeamUserEdit
