import axios from '../lib/axios'
import type {
    Application,
    CompanyResubmission,
    ConnectionResult,
    CurrentSituation,
    Totals,
} from '../types/Resubmission'

class ResubmissionsApi {
    async getTotals(cumulative: boolean): Promise<Totals> {
        const response = await axios.get<Totals>('resubmissions/totals', {
            params: { cumulative },
        })
        return response.data
    }

    async getAllParticipantCurrentSituations(
        cumulative: boolean
    ): Promise<CurrentSituation[]> {
        const response = await axios.get<CurrentSituation[]>(
            'resubmissions/participantCurrentSituations',
            { params: { cumulative } }
        )
        return response.data
    }

    async updateParticipantCurrentSituation(
        currentSituation: CurrentSituation
    ): Promise<CurrentSituation> {
        const response = await axios.put<CurrentSituation>(
            `resubmissions/participantCurrentSituations/${currentSituation.id}`,
            currentSituation
        )
        return response.data
    }

    async getAllParticipantApplications(
        cumulative: boolean
    ): Promise<Application[]> {
        const response = await axios.get<Application[]>(
            'resubmissions/participantApplications',
            { params: { cumulative } }
        )
        return response.data
    }

    async updateParticipantApplication(
        application: Application
    ): Promise<Application> {
        const response = await axios.put<Application>(
            `resubmissions/participantApplications/${application.id}`,
            application
        )
        return response.data
    }

    async getAllParticipantConnectionResults(
        cumulative: boolean
    ): Promise<ConnectionResult[]> {
        const response = await axios.get<ConnectionResult[]>(
            'resubmissions/participantConnectionResults',
            { params: { cumulative } }
        )
        return response.data
    }

    async updateParticipantConnectionResult(
        connectionResult: ConnectionResult
    ): Promise<ConnectionResult> {
        const response = await axios.put<ConnectionResult>(
            `resubmissions/participantConnectionResults/${connectionResult.id}`,
            connectionResult
        )
        return response.data
    }

    async getAllCompanyResubmissions(
        cumulative: boolean
    ): Promise<CompanyResubmission[]> {
        const response = await axios.get<CompanyResubmission[]>(
            'resubmissions/companyResubmissions',
            { params: { cumulative } }
        )
        return response.data
    }

    async updateCompanyResubmission(
        companyResubmission: CompanyResubmission
    ): Promise<CompanyResubmission> {
        console.log('resubmission', companyResubmission)
        const response = await axios.put<CompanyResubmission>(
            `resubmissions/companyResubmissions/${companyResubmission.id}`,
            companyResubmission
        )
        return response.data
    }
}

export const resubmissionsApi = new ResubmissionsApi()
