import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Card, Grid, IconButton, Tooltip } from '@material-ui/core'
import {
    Cancel as CancelIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import type Team from '../../../types/Team'
import { FormTextField } from '../../form'

interface TeamEditFormProps {
    team: Team
    onFormDirtyChange?: (value: boolean) => void
    onSave?: (value: Team) => void
    onDelete?: (value: Team) => void
}

const schema = Yup.object().shape({
    id: Yup.string().required('Erforderlich').max(10, '<= 11 Zeichen'),
    name: Yup.string().required('Erforderlich').max(255),
})

const TeamEditForm: FC<TeamEditFormProps> = (props) => {
    const { team, onFormDirtyChange, onSave, onDelete, ...other } = props

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            id: team.id || '',
            name: team.name || '',
            retentionPeriod: team.retentionPeriod || 0,
        },
        mode: 'onChange',
    })

    const onSubmit = (values) => {
        try {
            onSave(values)
            methods.reset(values)
            toast.success('Gespeichert!')
        } catch (err) {
            console.error(err)
            toast.error('Etwas ist schief gelaufen!')
        }
    }

    const handleDeleteTeam = (value: Team) => {
        if (onDelete) onDelete(value)
    }

    useEffect(() => {
        onFormDirtyChange?.(methods.formState.isDirty)
    }, [methods.formState.isDirty])

    if (!team) {
        return null
    }
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} {...other}>
                <Card>
                    <Box sx={{ p: 2, pt: 1 }}>
                        <Grid container spacing={1}>
                            <Grid container item spacing={2} lg={12}>
                                <Grid item lg={1}>
                                    <FormTextField
                                        fullWidth
                                        label="Kürzel"
                                        name="id"
                                        required
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid item lg={7}>
                                    <FormTextField
                                        fullWidth
                                        label="Bezeichnung"
                                        name="name"
                                        required
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid item lg={2}>
                                    <FormTextField
                                        fullWidth
                                        label="Aufbewahrungsfrist in Monate"
                                        name="retentionPeriod"
                                        variant="standard"
                                        type="number"
                                        inputProps={{
                                            inputMode: 'numeric',
                                            pattern: '[0-9]',
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    lg={2}
                                    justifyContent="flex-end"
                                    display="flex"
                                >
                                    <Tooltip title="Speichern">
                                        <IconButton type="submit">
                                            <SaveIcon fontSize="medium" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Zurück setzen">
                                        <IconButton
                                            onClick={() => methods.reset()}
                                        >
                                            <CancelIcon fontSize="medium" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Datensatz löschen">
                                        <IconButton
                                            onClick={() => {
                                                handleDeleteTeam(team)
                                            }}
                                        >
                                            <DeleteIcon fontSize="medium" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </form>
        </FormProvider>
    )
}

TeamEditForm.propTypes = {
    // @ts-ignore
    team: PropTypes.object.isRequired,
    onFormDirtyChange: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
}

export default TeamEditForm
